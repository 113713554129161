import { useDispatch, useSelector } from "react-redux";
import { EXLY_ACCOUNT__GET_FEATURE_ACCESSIBILITY } from "features/Account/redux/Account.actions";

export const useFeatureAccessibility = () => {
  const dispatch = useDispatch();
  const featureAccessibilty = useSelector(
    (state) => state.myAccount.featureAccessibilty
  );

  const fetchFeatureAccessibility = ({ featureKey }) => {
    dispatch({
      type: EXLY_ACCOUNT__GET_FEATURE_ACCESSIBILITY,
      featureKey,
    });
  };

  const isFeatureAccessible = ({ featureKey }) =>
    featureAccessibilty?.[featureKey] ?? false;

  return {
    featureAccessibilty,
    fetchFeatureAccessibility,
    isFeatureAccessible,
  };
};
