import { EXLY_ACCOUNT__SET_SHOW_WHITELABEL_UNLOCK_MODAL } from "features/Account/redux/Account.actions";
import { useDispatch, useSelector } from "react-redux";

export const useWhitelabelLock = () => {
  const dispatch = useDispatch();
  const showWhitelabelUnlockModal = useSelector(
    (state) => state.myAccount.showWhitelabelUnlockModal
  );

  const toggleWhitelabelUnlockModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: EXLY_ACCOUNT__SET_SHOW_WHITELABEL_UNLOCK_MODAL,
      payload: !showWhitelabelUnlockModal,
    });
  };

  return {
    showWhitelabelUnlockModal,
    toggleWhitelabelUnlockModal,
  };
};
