import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { is_empty } from "utils/validations";
import { Card } from "@my-scoot/component-library-legacy";
import moduleStyle from "./HighlightedFeatureCard.module.css";
import { useCart } from "ui/pages/Account/hooks/useCart";
import { EXLY_ACCOUNT__GET_HIGHLIGHTED_FEATURE } from "features/Account/redux/Account.actions";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

export const HighlightedFeatureCard = () => {
  const dispatch = useDispatch();
  const isDesktop = useDesktopMediaQuery();
  const highlightedFeature = useSelector((state) => state.myAccount.highlights);
  const { addItemToCart, handleGoToCart, getItemQuantityInCart } = useCart();

  React.useEffect(() => {
    dispatch({ type: EXLY_ACCOUNT__GET_HIGHLIGHTED_FEATURE });
  }, []);

  const currentSubscriptionBannerCardHeight = document.querySelector(
    "#CurrentSubscriptionBanner"
  )?.offsetHeight;

  if (is_empty(highlightedFeature)) return null;

  const handlePurchase = () => {
    if (highlightedFeature.has_credits) return;
    if (getItemQuantityInCart(highlightedFeature?.feature_key) > 0) {
      return handleGoToCart({ overrideCartEmptyCheck: true });
    }
    addItemToCart(highlightedFeature);
    handleGoToCart({ overrideCartEmptyCheck: true });
  };

  return (
    <Card
      className={`${moduleStyle.container} w-100 ml-0 mx-md-2 mt-2 mt-md-0 position-relative`}
    >
      {!highlightedFeature.has_credits && (
        <img
          src={`${window.location.origin}/assets/images/account/locked.png`}
          alt="locked"
          className={`${moduleStyle.lockedImg}`}
        />
      )}
      <div
        style={
          isDesktop
            ? {
                minHeight: currentSubscriptionBannerCardHeight,
                maxHeight: currentSubscriptionBannerCardHeight,
              }
            : {}
        }
        id="HighlightedFeatureCard"
        className={`d-flex align-items-start p-3 overflow-hidden`}
      >
        <img
          className={`${moduleStyle.logo}`}
          src={
            highlightedFeature.logo ??
            "https://dummyimage.com/93x85/eeecf9/4a3ab0.png&text=Your+Brand"
          }
          alt="highlight_img"
        />
        <div className={`ml-2 ml-md-4`}>
          <div
            className={`${moduleStyle.title}`}
            dangerouslySetInnerHTML={{ __html: highlightedFeature.title }}
          ></div>
          <div
            className={`mt-2 ${moduleStyle.description}`}
            dangerouslySetInnerHTML={{ __html: highlightedFeature.description }}
          ></div>
        </div>
      </div>
      <div
        onClick={handlePurchase}
        id="highlightedFeature_cta_text"
        className={`${
          moduleStyle.ctaContainer
        } p-2 d-flex justify-content-center ${
          highlightedFeature.has_credits ? "" : "pointer"
        }`}
        dangerouslySetInnerHTML={{ __html: highlightedFeature.cta_text }}
      ></div>
    </Card>
  );
};
