import React from "react";
import LockIcon from "@material-ui/icons/Lock";
import moduleStyle from "./WhitelabelModalDescription.module.css";

export const WhitelabelModalDescription = ({ modalDescription }) => {
  const {
    heading,
    subHeading = "With white-labelling you can expect the following changes",
    pointers = [
      "Manage footer section on your landing page and thank you page",
      "Remove Exly branding from all pages including sales page and checkout pages.",
      "Manage sender name and email for all email related communications",
      "Activate Whatsapp personal number for your Whatsapp messages",
    ],
  } = modalDescription;
  return (
    <div className="p-4 d-flex align-items-center flex-column">
      <LockIcon className={`${moduleStyle.lockIcon}`} />
      <div className={`${moduleStyle.title} mt-3 text-center`}>{heading}</div>
      <div className={`${moduleStyle.description} mt-3`}>
        {subHeading}
        <ol className={`${moduleStyle.ol}`}>
          {pointers.map((point, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{point}</li>
          ))}
        </ol>
      </div>
    </div>
  );
};
