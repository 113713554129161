import { FEATURE_KEYS } from "features/Account/Account.constants";
import { EXLY_ACCOUNT__GET_FEATURE_DETAILS } from "features/Account/redux/Account.actions";
import { useFeatureAccessibility } from "features/Account/utils/useFeatureAccessibility";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCart } from "ui/pages/Account/hooks/useCart";

export const useWhitelabel = () => {
  const { getItemQuantityInCart, addItemToCart, handleGoToCart } = useCart();
  const dispatch = useDispatch();
  const { fetchFeatureAccessibility, isFeatureAccessible } =
    useFeatureAccessibility();
  const whiteLabelDetails = useSelector(
    (state) => state.myAccount.featureDetails?.whitelabel
  );

  React.useEffect(() => {
    fetchFeatureAccessibility({ featureKey: FEATURE_KEYS.whitelabel });
    dispatch({
      type: EXLY_ACCOUNT__GET_FEATURE_DETAILS,
      featureKey: FEATURE_KEYS.whitelabel,
    });
  }, []);

  const handleWhitelabelPurchase = () => {
    if (getItemQuantityInCart(FEATURE_KEYS.whitelabel) > 0) {
      return handleGoToCart({ overrideCartEmptyCheck: true });
    }
    addItemToCart({
      ...whiteLabelDetails,
      feature_key: FEATURE_KEYS.whitelabel,
      units: 1,
    });
    handleGoToCart({ overrideCartEmptyCheck: true });
  };

  return {
    handleWhitelabelPurchase,
    isAccessible: isFeatureAccessible({ featureKey: FEATURE_KEYS.whitelabel }),
    whiteLabelDetails: whiteLabelDetails,
  };
};
